import React, { Component } from "react";
//import { Link } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";

// import moment from "moment";
// import { Map, Marker, Popup } from 'react-leaflet';
// import MarkerClusterGroup from "react-leaflet-markercluster";
// import L from "leaflet";
import { Doughnut } from "react-chartjs-2";
import "leaflet.awesome-markers";

// import MapTileLayer from "../../components/MapTileLayer";
// import GatewayStore from "../../stores/GatewayStore";
// import InternalStore from "../../stores/InternalStore";
import theme from "../../theme";
import ApplicationStore from "../../stores/ApplicationStore";

// class ListGatewaysMap extends Component {
//   constructor() {
//     super();

//     this.state = {
//       items: null,
//     };
//   }

//   componentDidMount() {
//     GatewayStore.list("", this.props.organizationID, 9999, 0, resp => {
//       this.setState({
//         items: resp.result,
//       });
//     });
//   }

//   render() {
//     if (this.state.items === null || this.state.items.length === 0) {
//       return(
//         <Card>
//           <CardHeader title="Gateways" />
//             <CardContent>
//               No data available.
//             </CardContent>
//         </Card>
//       );
//     }

//     const style = {
//       height: 600,
//     };

//     let bounds = [];
//     let markers = [];

//     const greenMarker = L.AwesomeMarkers.icon({
//       icon: "wifi",
//       prefix: "fa",
//       markerColor: "green",
//     });

//     const grayMarker = L.AwesomeMarkers.icon({
//       icon: "wifi",
//       prefix: "fa",
//       markerColor: "gray",
//     });

//     const redMarker = L.AwesomeMarkers.icon({
//       icon: "wifi",
//       prefix: "fa",
//       markerColor: "red",
//     });

//     for (const item of this.state.items) {
//       if (item.location.latitude === 0 && item.location.longitude === 0) {
//         continue;
//       }

//       const position = [item.location.latitude, item.location.longitude];

//       bounds.push(position);

//       let marker = greenMarker;
//       let lastSeen = "";

//       if (item.lastSeenAt === undefined || item.lastSeenAt === null) {
//         marker = grayMarker;
//         lastSeen = "Never seen online";
//       } else {
//         const ts = moment(item.lastSeenAt);
//         if (ts.isBefore(moment().subtract(5, 'minutes'))) {
//           marker = redMarker;
//         }

//         lastSeen = ts.fromNow();
//       }

//       markers.push(
//         <Marker position={position} key={`gw-${item.id}`} icon={marker}>
//           <Popup>
//             <Link to={`/organizations/${this.props.organizationID}/gateways/${item.id}`}>{item.name}</Link><br />
//             {item.id}<br /><br />
//             {lastSeen}
//           </Popup>
//         </Marker>
//       );
//     }

//     return(
//       <Card>
//         <CardHeader title="Gateways" />
//           <CardContent>
//             <Map bounds={bounds} maxZoom={19} style={style} animate={true} scrollWheelZoom={false}>
//               <MapTileLayer />
//               <MarkerClusterGroup>
//                 {markers}
//               </MarkerClusterGroup>
//             </Map>
//           </CardContent>
//       </Card>
//     );
//   }
// }

class DevicesActiveInactive extends Component {
  render() {
    let data = null;

    if (
      this.props.summary !== null &&
      (this.props.summary.activeCount !== 0 ||
        this.props.summary.inactiveCount !== 0)
    ) {
      data = {
        labels: ["Never seen", "Inactive", "Active"],
        datasets: [
          {
            data: [
              this.props.summary.neverSeenCount,
              this.props.summary.inactiveCount,
              this.props.summary.activeCount,
            ],
            backgroundColor: [
              theme.palette.warning.main,
              theme.palette.error.main,
              theme.palette.success.main,
            ],
          },
        ],
      };
    }

    return (
      <Card>
        <CardHeader title="Active devices within config period" />
        <CardContent>
          {data && <Doughnut data={data} />}
          {!data && <div>No data available.</div>}
        </CardContent>
      </Card>
    );
  }
}

class DevicesBatteryStatuses extends Component {
  getColor = (dr) => {
    return [
      "#ff5722",
      "#ff9800",
      "#ffc107",
      "#ffeb3b",
      "#cddc39",
      "#8bc34a",
      "#4caf50",
      "#009688",
      "#00bcd4",
      "#03a9f4",
      "#2196f3",
      "#3f51b5",
      "#673ab7",
      "#9c27b0",
      "#e91e63",
      "#D3BF60",
      "#8CB3D4",
      "#44C56E",
    ][dr];
  };

  render() {
    let data = null;

    if (
      this.props.summary !== null &&
      Object.keys(this.props.summary.batteryCount).length !== 0
    ) {
      data = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
          },
        ],
      };

      for (let bs in this.props.summary.batteryCount) {
        if (bs !== "82") data.labels.push(`${bs}%`);
        else data.labels.push(`Plugged`);

        data.datasets[0].data.push(this.props.summary.batteryCount[bs]);
        data.datasets[0].backgroundColor.push(this.getColor(bs - 82));
      }
    }

    return (
      <Card>
        <CardHeader title="Device battery status spread" />
        <CardContent>
          {data && <Doughnut data={data} />}
          {!data && <div>No data available.</div>}
        </CardContent>
      </Card>
    );
  }
}

class DevicesDataRates extends Component {
  getColor = (dr) => {
    return [
      "#ff5722",
      "#ff9800",
      "#ffc107",
      "#ffeb3b",
      "#cddc39",
      "#8bc34a",
      "#4caf50",
      "#009688",
      "#00bcd4",
      "#03a9f4",
      "#2196f3",
      "#3f51b5",
      "#673ab7",
      "#9c27b0",
      "#e91e63",
      "#44C56E",
      "#35A1F7",
      "#85CF4F",
      "#D7AA35",
      "#583E6D",
      "#EEDF85",
      "#0A3638",
      "#DCB7AB",
      "#925DD9",
      "#2406EA",
      "#5C888E",
      "#1E5C27",
      "#7A0F62",
      "#B3F222",
      "#506B58",
      "#E93D73",
    ][dr];
  };

  render() {
    let data = null;

    if (
      this.props.summary !== null &&
      Object.keys(this.props.summary.drCount).length !== 0
    ) {
      data = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
          },
        ],
      };

      for (let dr in this.props.summary.drCount) {
        data.labels.push(`RSSI ${dr}`);
        data.datasets[0].data.push(this.props.summary.drCount[dr]);
        data.datasets[0].backgroundColor.push(this.getColor(dr));
      }
    }

    return (
      <Card>
        <CardHeader title="Device RSSI spread" />
        <CardContent>
          {data && <Doughnut data={data} />}
          {!data && <div>No data available.</div>}
        </CardContent>
      </Card>
    );
  }
}

class ApplicationDashboard extends Component {
  constructor() {
    super();

    this.state = {
      devicesSummary: null,
      gatewaysSummary: null,
    };
  }

  componentDidMount() {
    ApplicationStore.getDevicesSummary(
      this.props.application.organizationID,
      this.props.application.id,
      (resp) => {
        this.setState({
          devicesSummary: resp,
        });
      }
    );
  }

  render() {
    return (
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <DevicesActiveInactive
            summary={this.state.devicesSummary}
            applicationID={this.props.application.id}
          />
        </Grid>

        <Grid item xs={4}>
          <DevicesDataRates
            summary={this.state.devicesSummary}
            applicationID={this.props.application.id}
          />
        </Grid>

        <Grid item xs={4}>
          <DevicesBatteryStatuses
            summary={this.state.devicesSummary}
            applicationID={this.props.application.id}
          />
        </Grid>
      </Grid>
    );
  }
}

export default ApplicationDashboard;
