import React from "react";

import { withStyles } from "@material-ui/core/styles";
// import TextField from '@material-ui/core/TextField';
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";

import FormComponent from "../../classes/FormComponent";
import Form from "../../components/Form";

const styles = {
  formLabel: {
    fontSize: 12,
  },
};

class FUOTADeploymentForm extends FormComponent {
  constructor() {
    super();

    this.state.file = null;

    this.onFileChange = this.onFileChange.bind(this);
  }


  onFileChange(e) {
    let object = this.state.object;

    if (e.target.files.length !== 1) {
      object.payload = "";

      this.setState({
        file: null,
        object: object,
      });
    } else {
      this.setState({
        file: e.target.files[0],
      });

      const reader = new FileReader();
      reader.onload = () => {
        const encoded = reader.result.replace(/^data:(.*;base64,)?/, '');
        object.payload = encoded;

        this.setState({
          object: object,
        });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  render() {
    if (this.state.object === undefined) {
      return null;
    }

    let fileLabel = "";
    if (this.state.file !== null) {
      fileLabel = `${this.state.file.name} (${this.state.file.size} bytes)`
    } else {
      fileLabel = "Select file..."
    }

    return(
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
      >

        <FormControl fullWidth margin="normal">
          <FormLabel className={this.props.classes.formLabel} required>Select firmware file</FormLabel>
          <Button component="label">
            {fileLabel}
            <input type="file" style={{display: "none"}} onChange={this.onFileChange} />
          </Button>
          <FormHelperText>
            This file will be fragmented and sent to the device(s).
          </FormHelperText>
        </FormControl>
      </Form>
    );
  }
}

export default withStyles(styles)(FUOTADeploymentForm);
